















import { computed, defineComponent } from "@vue/composition-api";
import Table from "@/components/Table.vue";
import useOidc from "@/composables/useOidc";
import CommonHelper from "@/utilities/common.helper";
import get from "@/utilities/get";
import ContentCard from "@/components/ContentCard.vue";
import { TableField } from "@/types/TableField";

/*
  Description: This claims component is used to show user's claims via a table. The table contains 2 columns such as Claim type and Claim value.
  For example:
    | Claim type | Claim value                          |
    | sub        | 5dd420f9-06e2-4388-b510-b2b88e770f75 |
    | ---        | ---                                  |

  Data: 
    - claims: list of user claims ({claimType: string; claimValue: string})
    - fields: defined table fields

  Methods:
*/
export default defineComponent({
  components: {
    Table,
    ContentCard,
  },
  setup(_, { root }) {
    const token = useOidc().user.value?.access_token ?? "";
    // decode the user token
    const userClaims = CommonHelper.decodeJsonWebToken(token);
    const claimKeys = Object.keys(userClaims);

    return {
      claims: computed(() =>
        claimKeys.map((key) => ({
          claimType: key,
          claimValue: get(userClaims, key),
        }))
      ),
      fields: computed<TableField[]>(() => [
        {
          key: "claimType",
          label: root.$tc("common.claim_type"),
          sortable: true,
          filter: false,
          width: "40%",
        },
        {
          key: "claimValue",
          label: root.$tc("common.claim_value"),
          sortable: true,
          filter: false,
        },
      ]),
    };
  },
});
