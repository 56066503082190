export default class CommonHelper {
  private static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += "==";
        break;
      }
      case 3: {
        output += "=";
        break;
      }
      default:
        throw new Error("Illegal base64url string!");
    }
    return decodeURIComponent((window as any).escape(window.atob(output)));
  }

  // Ref: http://blah.winsmarts.com/2016-5-Parsing_an_access_token_(JWT_Token)_using_TypeScript.aspx
  public static decodeJsonWebToken(token: string): any {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("JWT must have 3 parts");
    }
    const decoded = CommonHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error("Cannot decode the token");
    }
    return JSON.parse(decoded);
  }
}
